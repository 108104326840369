//@ts-ignore
//eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl';

//@ts-ignore
//eslint-disable-next-line import/no-webpack-loader-syntax
import MapLibreWorker from '!maplibre-gl/dist/maplibre-gl-csp-worker';

import {useMemo,useRef, useEffect, useState} from "react";
import mqtt, { MqttClient } from 'mqtt';
import useCompass, {
  requestPermission,
  isSafari,
  OrientationState,
} from "react-world-compass";
import plan_icon from "../plan_icon.svg";
import cursor from "../navigation.svg";
import navigate_close from "../navigate_close.svg";
import route_button from "../route_button.svg";
import marker_button from "../marker_button.svg";
import search_button from "../search_button.svg";
import north_icon from "../north_icon.svg";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import '../App.css';
import './NaviMenu.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import ChartQuery from './ChartQuery';
import {MapLibreEvent, MapMouseEvent, Map, MapGeoJSONFeature} from "maplibre-gl";
import {DepthUnit, Theme} from "../App";
import {Bounds} from "./Chartinfo";

maplibregl.workerClass = MapLibreWorker;

export class EncState {
    lng: number = parseFloat(window.localStorage.getItem("longitude") ?? "-122.4002");
    lat: number = parseFloat(window.localStorage.getItem("latitude") ?? "47.27984");
    zoom: number = parseFloat(window.localStorage.getItem("zoom") ?? "11.0");
}

// export class CurrentState {
//     lng: number = parseFloat(window.localStorage.getItem("longitude") ?? "-122.4002");
//     lat: number = parseFloat(window.localStorage.getItem("latitude") ?? "47.27984");
//     bearing: number = parseFloat(window.localStorage.getItem("bearing") ?? "90.0");
// }

// export function storeCurrentState(state: CurrentState) {
// 	window.localStorage.setItem("longitude", `${state.lng}`)
// 	window.localStorage.setItem("latitude", `${state.lat}`)
// 	window.localStorage.setItem("bearing", `${state.bearing}`)
// 	console.log(`stored EncState = ${JSON.stringify(state)}`)
// }

export function storeEncState(state: EncState) {
    window.localStorage.setItem("longitude", `${state.lng}`)
    window.localStorage.setItem("latitude", `${state.lat}`)
    window.localStorage.setItem("zoom", `${state.zoom}`)
    console.log(`stored EncState = ${JSON.stringify(state)}`)
}

var destination: Bounds | null = null

export function setDestination(bounds: Bounds) {
	console.log(`setting destination to ${JSON.stringify(bounds)}`);
    destination = bounds;
}

type EncProps = {
    depths: DepthUnit,
    theme: Theme,
    custom: string | null,
}

function scaleUnit(depths: DepthUnit) {
    switch (depths) {
        case DepthUnit.meters:
            return "metric"
        case DepthUnit.feet:
            return "nautical"
        case DepthUnit.fathoms:
            return "nautical"
        default:
            return "nautical"
    }
}

export function Enc(props: EncProps) {
    const mapContainer = useRef(null);
    const map = useRef<Map | null>(null);
    const [show, setShow] = useState<MapGeoJSONFeature[] | null>(null);
    const handleClose = () => setShow(null);

    const encUpdater = (state: EncState) => {
        storeEncState(state);
    }

    // const currentEncUpdater = (state: CurrentState) => {
    //     storeCurrentState(state);
    // }

    const markerRef = useRef<maplibregl.Marker | null>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    // const prevDegRef = useRef<number | null>(null);
    const [_bearing, setBearing] = useState<number | null>(null);
    const [_currentLng, setCurrentLng] = useState<number | null>(null);
    const [_currentLat, setCurrentLat] = useState<number | null>(null);
    //const threshold = 5; // Define the threshold for delta alpha

    // May change to fetching data from the MQTT server
    // const _compass = useCompass(100);
    // const [compass, setCompass] = useState<OrientationState | null>(null);

    const [client, setClient] = useState<MqttClient | null>(null);
    const [isConnected, setIsConnected] = useState<boolean>(false);

    const toggleManual = () => {
      setIsVisible(!isVisible);
    };

	useEffect(() => {
		let cMap: Map | null = map.current;
		if (cMap) {
			let url = `/v1/style/${props.depths}/${props.theme}?c=${props.custom}`
			console.log(`loading style url ${url}`)
			cMap?.setStyle(url)
			return; //stops map from intializing more than once
		}

		let encState = new EncState();
		let newMap = new maplibregl.Map({
			container: mapContainer.current,
			style: `/v1/style/${props.depths}/${props.theme}?c=${props.custom}`,
			center: [encState.lng, encState.lat],
			zoom: encState.zoom
		});

		newMap.addControl(new maplibregl.NavigationControl(), 'bottom-right');
		newMap.addControl(new maplibregl.ScaleControl({ unit: scaleUnit(props.depths) }));

		const customMarker = document.createElement('div');
		customMarker.style.backgroundImage = `url(${cursor})`;
		customMarker.style.width = '50px';
		customMarker.style.height = '50px';
		customMarker.style.backgroundSize = 'contain';
		customMarker.style.backgroundRepeat = 'no-repeat';
		customMarker.style.transform = 'rotate(0deg)'

		const marker = new maplibregl.Marker({
			    element: customMarker,
			})
		    .setLngLat([_currentLng, _currentLat])
		    .addTo(newMap);

		markerRef.current = marker;

        newMap.on('moveend', function (e: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined>) {
            let center = e.target.getCenter();
            let zoom = e.target.getZoom();
            console.log(`moved to Zoom(${zoom}) ${center.toString()}`)
            encUpdater({
                lat: center.lat,
                lng: center.lng,
                zoom: zoom,
            });
        });


        newMap.on('click', function (e: MapMouseEvent) {
            const bbox = [
                [e.point.x - 5, e.point.y - 5],
                [e.point.x + 5, e.point.y + 5]
            ];
            let lnams = new Set<string>();
            let features = newMap.queryRenderedFeatures(bbox);
            let filtered = features.filter((each: MapGeoJSONFeature) => {
                let lnam = each.properties["LNAM"]
                if (lnam) {
                    let f = !lnams.has(lnam);
                    lnams.add(lnam)
                    return f;
                } else {
                    return true;
                }
            })
            setShow(filtered);
        });
        map.current = newMap;

        if (destination) {
            newMap.fitBounds([[destination.leftLng, destination.topLat], [destination.rightLng, destination.bottomLat]])
            destination = null
        }
        // Clean up the connection when the component unmounts
        // return () => {
        //   mqttClient.end();
        // };
	},[]);

    useEffect(() => {
	    //const mqttClient = mqtt.connect('ws://localhost:8083/mqtt');
	    const mqttClient = mqtt.connect('wss://192.168.10.163:8084/mqtt',
		    {
			    connectTimeout: 4000,
			    clientId: 'web_client_enc',
			    clean: true,
			    reconnectPeriod: 1000,
			    protocol: 'ws',
		      	username: 'user',
				password: 'user',
			});

	    mqttClient.on('connect', () => {
	      setIsConnected(true);
	      console.log('Connected to MQTT broker');

	      // Subscribe to a topic
	      mqttClient.subscribe('raw/sensor/GPS', (err) => {
	        if (err) {
	          console.error('Failed to subscribe:', err);
	        } else {
	          console.log('Subscribed to topic: raw/sensor/GPS');
	        }
	      });
	      mqttClient.subscribe('raw/sensor/IMU', (err) => {
	        if (err) {
	          console.error('Failed to subscribe:', err);
	        } else {
	          console.log('Subscribed to topic: raw/sensor/IMU');
	        }
	      });
	    });

	    mqttClient.on('message', (topic, payload) => {
			//let currentState = new CurrentState();
			const parsed = JSON.parse(payload.toString());
			if (topic === 'raw/sensor/GPS') {
				setCurrentLng(parsed.longitude);
				setCurrentLat(parsed.latitude);
				// if(_currentLat && _currentLng){
				// 	currentState.lat = _currentLat;
				// 	currentState.lng = _currentLng;
				// 	currentEncUpdater(currentState);
				// }
			}
			if(topic === 'raw/sensor/IMU'){
				const currentBearing = parsed.bearing;
				setBearing(currentBearing);
				// if(_bearing){
				// 	currentState.bearing = _bearing;
				// 	currentEncUpdater(currentState);
				// }
			}
	    });

	    mqttClient.on('error', (err) => {
	      console.error('MQTT connection error:', err);
	    });

	    setClient(mqttClient);
	}, []);

    useEffect(() => {
		if (map.current) {
			updateMarkerPositionAndRotation();
		}
	}, [_currentLat, _currentLng, _bearing]);

    function clipboard() {
        navigator.clipboard.writeText(JSON.stringify(show));
    }

    function updateMarkerPositionAndRotation(){
        if (markerRef.current) {
        	//let currentState = new CurrentState();
         	console.log(`Updating marker position to ${_currentLat}, ${_currentLng}`);
            markerRef.current.setLngLat([_currentLng, _currentLat]);

            // const customMarker = markerRef.current.getElement();
            // if (customMarker) {
            //     customMarker.style.transform = `rotate(${currentState.bearing}deg)`;
            // }
        }
    };

    function recenter() {
		if (map.current && _currentLat && _currentLng) {
			console.log(`Recentering to ${_currentLat}, ${_currentLng}`);
			encUpdater({
				lat: _currentLat,
				lng: _currentLng,
				zoom: 11,
			});
			map.current.flyTo({
				center: [_currentLng, _currentLat],
				zoom: 11,
				speed: 1,
				curve: 1,
				easing(t: number) {
					return t;
				},
			});
		}
	}


    return (
        <>
        	<div ref={mapContainer} className="Fill">
	         	<div className="maptoolbar">
					{!isVisible && (
						<button style={{ border: 'none', background: 'none' }} onClick={toggleManual}>
		      				<img src={plan_icon} alt="Click Me" style={{position:'absolute',  top: '0px' , left: '0px', width: '88px', height: '80px' }} />
		          		</button>
					)}

					{_bearing !== null && (
						<button style={{ border: 'none', background: 'none' }}>
							<img src={north_icon} alt="Click Me" style={{ position: 'absolute', top: '0px', right: '0%', width: '88px', height: '80px', transform: `rotate(${_bearing}deg)` }} onClick={recenter}/>
						</button>
					)}
		        </div>

				{isVisible && (
			        <div className="navi_menu">
						<div className="navi-header">
							<div className="navi_text">
								Navigate
							</div>
							<button style={{ position : 'absolute',width: '36px' , height: '36px',top: '20px',left:'404px',border: 'none', background: 'none' }} onClick={toggleManual}>
								<img src={navigate_close} alt="Close Me" />
							</button>
						</div>
						<div className="navi-body">
							<button style={{ border: 'none', background: 'none' }} onClick={toggleManual}>
			      				<img src={route_button} alt="Click Me" style={{position:'absolute',  top: '20px' , left: '16px', width: '109px', height: '44px' }} />
			          		</button>

							<button style={{ border: 'none', background: 'none' }} onClick={toggleManual}>
			      				<img src={marker_button} alt="Click Me" style={{position:'absolute',  top: '20px' , left: '161px', width: '109px', height: '44px' }} />
			          		</button>

							<button style={{ border: 'none', background: 'none' }} onClick={toggleManual}>
			      				<img src={search_button} alt="Click Me" style={{position:'absolute',  top: '20px' , left: '321px', width: '109px', height: '44px' }} />
			          		</button>
						</div>
			        </div>
			    )}
         	</div>

            <Modal show={show != null} onHide={handleClose} dialogClassName="modal-xl">
                <Modal.Header closeButton><Modal.Title>Chart Query</Modal.Title></Modal.Header>
                <Modal.Body>
                    <DisplayQuery object={show}/>
                    <Modal.Footer>
                        <Button variant="primary" onClick={clipboard}>Copy Json</Button>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    );
}

type DisplayQueryProps = {
    object: MapGeoJSONFeature[] | null
}

function DisplayQuery(props: DisplayQueryProps) {
    if (props.object) {
        return (
            <>
                <Accordion>
                    {
                        props.object.map((each, i) => {
                            return <ChartQuery key={i} feature={each} eventKey={`${i}`}/>
                        })
                    }
                </Accordion>
            </>
        );
    } else {
        return (<></>);
    }
}
